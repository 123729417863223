.footer {
  background-color: grey;
  width: 100%;
  padding: 20px 0 10px 50px;
}

.container {
  max-width: 100%;
  margin: auto;
  display: flex;
}

.row {
  justify-content: space-around;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-col {
  width: 25%;
  padding: 0 15px;
}

.footer-col h4 {
  font-size: 18px;
  color: bisque;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: antiquewhite;
  width: 50px;
  height: 2px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li {
  color: #ddd;
  display: block;
}

.footer-col ul a {
  font-weight: 300;
  font-size: 1rem;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-col ul a:hover {
  color: #fff;
  padding-left: 7px;
}

.fa {
  padding: 20px;
  width: 40%;
  text-decoration: none;
  margin: 5px 2px;
  transition: all 3s ease;
}

.fa-facebook {
  background: #3b5998;
  color: white;
}

.fa-twitter {
  background: #55acee;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}
