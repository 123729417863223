.navbar {
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.nav-logo {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
}

/* .nav-logo img {
    width: 100px;
} */
.nav-logo h1 {
  background-image: url('assets/ethiopian_happy_kids.jpeg');
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-links {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}

.nav-links Link {
  color: black;
  text-decoration: none;
  margin: 20px;
}

.nav-links button {
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
}
