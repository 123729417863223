* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

:root {
--primary-color: #888888; /* Subtle gray */
--second-color: #ffffff;
--black-color: #000000;
}

.user-container {
display: flex;
/* justify-content: flex-end; */
/* max-width: 100%; */
min-height: 100vh;
}

.user-picture img {
width: 100%;
height: 100%;
display: block;
/* object-fit: cover; */
}

.user-switcher-container {
display: flex;
align-items: center;
justify-content: center;
width: 100%;
}

.user-form-container {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.user-switcher {
transition: transform .85s;
transform-style: preserve-3d;
position: relative;
justify-content: center;
display: block;
width: 80%;
}

.user-switcher.rotate {
transform: rotateY( 180deg );
}

.user-login-form-wrapper {
z-index: 2;
}

.user-create-form-wrapper {
z-index: 3;
transform: rotateY(180deg);
}

.user-login {
width: 100%;
text-align: center;
justify-content: center;
padding: 2.5em;
border: solid .5px black;
border-radius: 15px;
color: var(--black-color);
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
background-color: var(--second-color); 
}

.user-create { 
width: 100%;
text-align: center;
justify-content: center;
padding: 2.5em 2.5em 3.5em 2.5em;
border: solid .5px black;
border-radius: 15px;
color: var(--black-color);
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
background-color: var(--second-color); 
}

.user-login-header {
text-align: center;
margin-bottom: 40px;
}

.user-create-header {
text-align: center;
margin-bottom: 40px;
}

.user-login-header span {
font-size: 30px;
color: var(--black-color);
}

.user-create-header span {
font-size: 30px;
color: var(--black-color);
}

.user-forgot-text {
text-align: right;
margin-top: 10px;
margin-bottom: 20px;
font-size: 15px;
}

.user-input-box {
position: relative;
display: flex;
flex-direction: column;
margin: 20px 0;
} 

.user-create-input-box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.user-input-field {
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: var(--black-color);
  padding: 0 20px;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  outline: none;
}

.user-create-input-field {
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: var(--black-color);
  padding: 0 20px;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  outline: none;
}

.user-input-field:focus ~ .user-label,
.user-input-field:not(:placeholder-shown) ~ .user-label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  border-radius: 30px;
  color: var(--black-color);
  padding: 0 10px;
  background-color: var(--second-color);
}

.user-create-input-field:focus ~ .user-create-label,
.user-create-input-field:not(:placeholder-shown) ~ .user-create-label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  border-radius: 30px;
  color: var(--black-color);
  padding: 0 10px;
  background-color: var(--second-color);
}

.user-label {
  position: absolute;
  top: 18px;
  left: 20px;
  transition: 0.2s;
  color: var(--primary-color);
}

.user-create-label {
  position: absolute;
  top: 18px;
  left: 20px;
  transition: 0.2s;
  color: var(--primary-color);
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
  color: var(--black-color);
}

.user-input-submit {
  width: 100%;
  padding: 10px;
  background-color: var(--second-color);
  color: var(--black-color);
  border: solid 2px black;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.user-create-input-submit {
  width: 100%;
  padding: 10px;
  background-color: var(--second-color);
  color: var(--black-color);
  border: solid 2px black;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.user-input-submit:hover {
  background-color: #a9a9a9;
}

.rotate-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
}

.rotate-button:hover {
  color: var(--black-color)
}

.user-register, .user-login-change {
  text-align: center;
  margin-top: 20px;
} 

