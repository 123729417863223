* {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
:root {
  --primary-color: #888888; /* Subtle gray */
  --second-color: #ffffff;
  --black-color: #000000;
}
  
.admin-container {
  display: flex;
  /* justify-content: flex-end; */
  /* max-width: 100%; */
  min-height: 100vh;
}

.admin-picture img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.admin-switcher-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.admin-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.admin-switcher {
  transition: transform .85s;
  transform-style: preserve-3d;
  position: relative;
  justify-content: center;
  width: 80%;
}

.admin-switcher.rotate {
  transform: rotateY( 180deg );
}

.admin-login-form-wrapper {
  z-index: 2;
}

.admin-create-form-wrapper {
  z-index: 3;
  transform: rotateY(180deg);
}

.admin-login {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 2.5em;
  border: solid .5px black;
  border-radius: 15px;
  /* color: var(--black-color); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* background-color: var(--second-color);  */
}

.admin-create { 
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 2.5em 2.5em 3.5em 2.5em;
  border: solid .5px black;
  border-radius: 15px;
  color: var(--black-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--second-color); 
}

.admin-login-header {
  text-align: center;
  margin-bottom: 40px;
}

.admin-create-header {
  text-align: center;
  margin-bottom: 40px;
}

.admin-login-header span {
  font-size: 30px;
  color: var(--black-color);
}

.admin-create-header span {
  font-size: 30px;
  color: var(--black-color);
}

.admin-forgot-text {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 15px;
}

.admin-input-box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
} 

.admin-create-input-box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.admin-input-field {
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: var(--black-color);
  padding: 0 20px;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  outline: none;
}

.admin-create-input-field {
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: var(--black-color);
  padding: 0 20px;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  outline: none;
}

.admin-input-field:focus ~ .admin-label,
.admin-input-field:not(:placeholder-shown) ~ .admin-label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  border-radius: 30px;
  color: var(--black-color);
  padding: 0 10px;
  background-color: var(--second-color);
}

.admin-create-input-field:focus ~ .admin-create-label,
.admin-create-input-field:not(:placeholder-shown) ~ .admin-create-label {
  top: -10px;
  left: 10px;
  font-size: 14px;
  border-radius: 30px;
  color: var(--black-color);
  padding: 0 10px;
  background-color: var(--second-color);
}

.admin-label {
  position: absolute;
  top: 18px;
  left: 20px;
  transition: 0.2s;
  /* color: var(--primary-color); */
}

.admin-create-label {
  position: absolute;
  top: 18px;
  left: 20px;
  transition: 0.2s;
  /* color: var(--primary-color); */
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
  color: var(--black-color);
}

.admin-input-submit {
  width: 100%;
  padding: 10px;
  background-color: var(--second-color);
  color: var(--black-color);
  border: solid 2px black;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.admin-create-input-submit {
  width: 100%;
  padding: 10px;
  background-color: var(--second-color);
  color: var(--black-color);
  border: solid 2px black;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.admin-input-submit:hover {
  background-color: #a9a9a9;
}

.rotate-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-color);
}

.rotate-button:hover {
  color: var(--black-color)
}

.admin-register, .user-login-change {
  text-align: center;
  margin-top: 20px;
} 

.error {
  text-rendering: optimizeLegibility;
  color: black;
  background-color: #ffffff;
}