.about-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 100vh;
}
/* 
.text-container {
  border: solid 3px black;
  background-color: aliceblue;
  width: 700px;
  position: relative;
  padding: 7.5em 2.5em 4em 2.5em;
  text-align: center;
  top: 0;
} */
