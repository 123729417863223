.contact-us-container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 100vh;
}

.contact-us-info {
  border: solid 3px black;
  background-color: aliceblue;
  width: 700px;
  position: relative;
  padding: 7.5em 2.5em 4em 2.5em;
  text-align: center;
  top: 0;
}

.contact-header span {
  font-size: 30px;
}

.contact-input-box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.contact-label {
  color: black;
}

.contact-input-field {
  border-radius: 10px;
  width: 75%;
  text-align: center;
  margin-left: -10px;
  margin: 0 auto;
}
