.donate-container {
  background-color: antiquewhite;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 100vh;
}

.donees {
  border: 3px solid black;
  width: 100%;
  height: 100vh;
  margin: 1em;
}

.donee-item {
  display: flex;
  width: 100%;
  height: 100px;
}

.donee-image {
  border: solid black 3px;
  height: 100px;
  max-width: 20%;
}

.donee-info {
  border: solid black 3px;
  max-width: 70%;
  height: 100%;
}

.donee-icons {
  border: solid black 3px;
  display: flex;
  align-items: center;
  justify-content: right;
  max-width: 10%;
  height: 100px;
}