.form-wrapper {
  background-image: url('assets/ethiopian_happy_kids.jpeg');
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.forgot {
  position: relative;
  width: 600px;
  backdrop-filter: blur(5px);
  border: solid 5px black;
  background-color: white;
  border-radius: 15px;
  padding: 5.5em 2.5em 4em 2.5em;
  color: var(--black-color);
  display: flex;
}

.forgot-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 100px;
}

.forgot-header span {
  font-size: 30px;
  color: var(--black-color);
}

.email-reset {
  justify-content: center;
  align-items: center;
  width: 500px;
}

.forgot-input-box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.forgot-input-field {
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: var(--black-color);
  padding-inline: 20px 50px;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  outline: none;
}

.forgot-submit {
  align-items: center;
  justify-content: center;
}

.forgot-input-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
}