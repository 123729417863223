.home {
  border: solid black;
}

.home-page-content {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mission-statement {
  border: solid 3px black;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  opacity: 0.75;
  backdrop-filter: blur(5px);
  height: 800px;
  width: 800px;
}

.mission-statement h1 {
  font-size: 50px;
}

.mission-statement p {
  margin: 50px;
  text-align: center;
}

.button {
  margin-top: 50px;
  position: relative;
  overflow: hidden;
  border: 1px solid #18181a;
  color: #18181a;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  padding: 18px 18px 17px;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 25px;
}

.button span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button:after {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

.picture {
  border: solid 3px purple;
}

.responsive-pie-chart {
}

.pie-data {
  /* border: solid 2px blue; */
  height: 450px;
}

.picture-panel {
}

.footer {
  background-color: white;
}
